import { useEffect, useState } from "react";
import cn from 'classnames';
import { popup, ButtonResult, ButtonType, PopupSize, QuantitySelector, updateCartItem, addRemanAndDepositItemsToCart, RemanufacturedChoice, getAvailabilityDisplay } from "ui";
import { Inventories } from 'ui/src/components/Inventories/Inventories';
import { Item } from "./types";
import { RemanResults } from "ui/src/types";
import * as styles from "./ItemPart.module.scss";

export type Selectable = { selected?: boolean };
export type SingleGroupItem = Omit<Item & Selectable, 'coordinate'>;

const translationState = window.app.preloadState.translation;

const showInventoryPopup = (part: SingleGroupItem) => {
    popup(
        translationState["inventoryPopup.stockStatusAtFacility"],
        <Inventories itemCode={part.code} itemDisplayName={part.displayName} />,
        [
            { label: translationState["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
        ],
        PopupSize.Large
    );
}

type ItemPartProps = {
    identifier: string;
    isSelected: boolean;
    popupSelection: boolean;
    onSelect: (code: string) => void;
    parts: SingleGroupItem[];
    onDropdownSelect: (code: string | null) => void;
}

export const ItemPart = ({ identifier, isSelected, onSelect, parts, popupSelection, onDropdownSelect }: ItemPartProps) => {

    const params = new URLSearchParams(location.search);

    useEffect(() => {
        const articleNumberString = params.get("articleNumber");
        if (articleNumberString) {
            const partIndex = parts.findIndex(part => part.code === articleNumberString);
            if (partIndex > -1) {
                setPartIndex(partIndex + 1);
            }
        }
    }, []);

    const [partIndex, setPartIndex] = useState<number>(parts.length === 1 ? 1 : 0);
    const selectPart = (index: number) => {
        setPartIndex(index);
        parts.forEach(p => p.selected = false);
        if (index > 0) {
            parts[index - 1].selected = true;
            onDropdownSelect(parts[index - 1].code);
        } else {
            onDropdownSelect(null);
        }
    }
    const noPrice = !parts[partIndex - 1]?.unitPrice;

    const showRemanufacturePopUp = async (itemCode: string) => {

        let result: RemanResults[string] = undefined!;

        const changeResult = (results: RemanResults) => {
            result = results[itemCode];
        }

        const remanQuantity = parts[partIndex - 1].remanQuantity;

        const override = remanQuantity
            ? {
                [itemCode]: remanQuantity
            } : undefined;

        const popupResult = await popup(
            translationState["remanufacturedItemPopUp.selectItemToAddToCart"],
            <RemanufacturedChoice changeResult={changeResult} sparePartIds={[itemCode]} quantityOverride={override} />,
            [
                { label: translationState["remanufacturedItemPopUp.remove"], result: ButtonResult.No, type: ButtonType.Link, className: "deleteButton", visible: override != null },
                { label: translationState["remanufacturedItemPopUp.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translationState["remanufacturedItemPopUp.ok"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.ExtraLarge,
            "noPaddingsPopUp"
        );
        if (popupResult == ButtonResult.Ok) {
            return result;
        } else if (popupResult == ButtonResult.No) {
            parts[partIndex - 1].remanQuantity = 0;
            return {
                selectedReman: false,
                sparePartQuantity: 0,
                remanQuantity: 0
            };
        } else {
            return undefined;
        }
    }

    const onAddReman = async (code: string, quantity: number) => {
        await addRemanAndDepositItemsToCart(code, quantity);
        parts[partIndex - 1].remanQuantity = quantity;
    }

    return (<div onClick={() => onSelect(parts[0].position)}>
        <div id={identifier} className={cn("evItemPartTicket itemPart", popupSelection && "d-md-none", isSelected && "selected")}>
            <h4>{parts[0].position}</h4>
            {partIndex > 0 && <>
                <FirstPart part={parts[partIndex - 1]} noPrice={noPrice} onAddReman={onAddReman} showRemanufacturePopUp={showRemanufacturePopUp} />
                <ItemRows part={parts[partIndex - 1]} noPrice={noPrice} mobile={false} onAddReman={onAddReman} showRemanufacturePopUp={showRemanufacturePopUp} />
            </>}
            {partIndex === 0 && <select className="variantSelect" value={partIndex} onChange={ev => selectPart(+ev.target.value)}>
                <option value={0}>{translationState["explodedViewTranslations.selectVariant"]}</option>
                {parts.map((part, i) => <option key={part.code} value={i + 1}>{part.displayName} | #{part.code}</option>)}
            </select>
            }
        </div>
        {popupSelection && <div className="evItemPartMobileTicket d-none d-md-flex">
            <div className="evItemPartTicket itemPart selected">
                <h4>{parts[0].position}</h4>
                {partIndex > 0 && <FirstPart part={parts[partIndex - 1]} noPrice={noPrice} onAddReman={onAddReman} showRemanufacturePopUp={showRemanufacturePopUp} />}
                {partIndex === 0 && <select className="variantSelect" value={partIndex} onChange={ev => selectPart(+ev.target.value)}>
                    <option value={0}>{translationState["explodedViewTranslations.selectVariant"]}</option>
                    {parts.map((part, i) => <option key={part.code} value={i + 1}>{part.displayName} | #{part.code}</option>)}
                </select>}
            </div>
            {partIndex > 0 && <ItemRows part={parts[partIndex - 1]} noPrice={noPrice} mobile={true} onAddReman={onAddReman} showRemanufacturePopUp={showRemanufacturePopUp} />}
        </div>}
        {parts.length > 1 && partIndex !== 0 && <select className="variantSelect" value={partIndex} onChange={ev => selectPart(+ev.target.value)}>
            <option value={0}>{translationState["explodedViewTranslations.selectVariant"]}</option>
            {parts.map((part, i) => <option key={part.code} value={i + 1}>{part.displayName} | #{part.code}</option>)}
        </select>
        }
    </div>)
}

type FirstPartProps = {
    part: SingleGroupItem;
    noPrice: boolean;
    onAddReman: (code: string, quantity: number) => Promise<void>;
    showRemanufacturePopUp: (itemCode: string) => Promise<RemanResults[string] | undefined>;
}

const FirstPart = ({ part, noPrice, onAddReman, showRemanufacturePopUp }: FirstPartProps) => {

    const [availabilityDisplay, setAvailabilityDisplay] = useState(part.inventory?.warehouse?.availabilityDisplay);
    const [hasStockAvailable, setHasStockAvailable] = useState(part.inventory?.branch?.hasEnoughStock ?? false);

    const onItemUpdate = async (code: string, quantity: number) => {
        await updateCartItem(code, quantity);
        const stockAvailability = await getAvailabilityDisplay(code);
        if (stockAvailability.availability) {
            setAvailabilityDisplay(stockAvailability.availability);
        }
        setHasStockAvailable(stockAvailability.hasEnoughInStock);
    };

    return <>
        <div className="description">
            <div className="information">
                <h4><a href={part.url}>{part.displayName}</a></h4>
                <a href={part.url} className="itemNumber firstLetterCapital">{translationState["sparePartItem.articleNo"]}: {part.code}</a>
                {part.labels?.length > 0 &&
                    <p className="labels">
                        {part.labels.map(label =>
                            <span key={label.text} className="label">{label.text}</span>
                        )}
                    </p>
                }
                <div className="delivery">
                    <div>
                        <p className="firstLetterCapital">{translationState["sparePartItem.quantityFrp"]}: {part.inventory?.packageQuantity ?? 1}</p>
                        {!!part.itemUnitId && <p className="firstLetterCapital">{translationState["sparePartItem.unit"]}: {part.itemUnitId}</p>}
                    </div>
                    <div className="d-lg-none">
                        {!part.showAddToCartButton ? <>
                            <span
                                className={cn("truckIcon", {
                                    "redText": !part.inventory?.warehouse?.isAvailable ?? "false",
                                    "greenText": part.inventory?.warehouse?.isAvailable ?? "false"
                                })}
                            >
                                {(part.inventory?.warehouse?.isAvailable ?? "false") ? <span dangerouslySetInnerHTML={{ __html: availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translationState["inventoryPopup.notInStock"]}</span>}
                            </span>
                            {part.inventory?.branch?.branchName ?
                                <span
                                    onClick={() => showInventoryPopup(part)}
                                    className={cn("", {
                                        "greenText": hasStockAvailable,
                                        "redText": !hasStockAvailable,
                                    })}
                                >
                                    {part.inventory?.branch?.branchName}
                                </span>
                                :
                                <span
                                    onClick={() => showInventoryPopup(part)}
                                >
                                    {translationState["inventories.selectBranch"]}
                                </span>
                            }
                        </> : <span className={"truckIcon greenText"}>
                            <span className={"d-inline firstLetterCapital"}>{translationState["common.deliveryTimeToBeChecked"]}</span>
                        </span>}
                    </div>
                </div>
            </div>
        </div>
        <div className={cn('cart d-lg-none', noPrice && "noBorder center")}>
            {
                !part.showRequestQuotationByEmail && !part.showContactSupport && <div className="prices">
                    <p className="newPrice">{part.discountedPrice ?? part.unitPrice}</p>
                    {!!part.discountedPrice && <>
                        <p className="oldPrice">{part.unitPrice}</p>
                        <p className="discount firstLetterCapital">{translationState["sparePartItem.discount"]}: {part.discountPercentageDisplay}</p>
                    </>}
                </div>
            }
            <QuantitySelector
                hasRemanInCart={!!part.remanQuantity}
                forceMinValue={false}
                step={part.inventory?.quantityStep ?? 1}
                min={part.inventory?.minimumQuantity ?? 0}
                max={part.inventory?.maximumQuantity ?? 0}
                initialValue={part.quantity}
                showLogin={part.showLogin}
                showNotAuthorized={part.showNotAuthorized}
                showRequestQuotationByEmail={part.showRequestQuotationByEmail}
                showContactSupport={part.showContactSupport}
                showBuyButton={part.showBuyButton}
                showAddToCartButton={part.showAddToCartButton}
                requestQuotationByEmail={part.requestQuotationByEmail}
                contactSupportLink={part.contactSupportLink}
                onRemove={(f: () => void) => onItemUpdate(part.code, 0).then(() => f())}
                onChange={(quantity: number) => onItemUpdate(part.code, quantity)}
                buttonLabel={translationState["sparePartItem.buy"]}
                buttonLabelAddToCart={translationState["sparePartItem.addToCart"]}
                buttonLabelReman={translationState["sparePartItem.boughtReman"]}
                buttonLabelAdded={translationState["sparePartItem.added"]}
                buttonLabelRemanAdded={translationState["sparePartItem.addedReman"]}
                buttonLabelRemanBase={translationState["sparePartItem.choose"]}
                onAddReman={onAddReman.bind(null, part.code)}
                showRemanufacturePopUp={part.hasRemanItem ? showRemanufacturePopUp.bind(undefined, part.code) : undefined}
                hasRemanAvailable={part.hasRemanItem}
            />
        </div>
    </>;
}

type ItemRowsProps = {
    part: SingleGroupItem;
    noPrice: boolean;
    mobile: boolean;
    onAddReman: (code: string, quantity: number) => Promise<void>;
    showRemanufacturePopUp: (itemCode: string) => Promise<RemanResults[string] | undefined>;
}

const ItemRows = ({ part, noPrice, mobile, onAddReman, showRemanufacturePopUp }: ItemRowsProps) => {

    const [availabilityDisplay, setAvailabilityDisplay] = useState(part.inventory?.warehouse?.availabilityDisplay);
    const [hasStockAvailable, setHasStockAvailable] = useState(part.inventory?.branch?.isAvailable ?? false);

    const onItemUpdate = async (code: string, quantity: number) => {
        await updateCartItem(code, quantity);
        const stockAvailability = await getAvailabilityDisplay(code);
        if (stockAvailability.availability) {
            setAvailabilityDisplay(stockAvailability.availability);
        }
        setHasStockAvailable(stockAvailability.hasEnoughInStock);
    };

    return <div className={cn("itemRows", !mobile && "d-nlg-none")}>
        <div>
            <div className="firstLetterCapital">{translationState["sparePartItem.stockStatus"]}:</div>
            <div>
            {!part.showAddToCartButton ? <>
                <span
                    className={cn("truckIcon", {
                        "redText": !part.inventory?.warehouse?.isAvailable ?? "false",
                        "greenText": part.inventory?.warehouse?.isAvailable ?? "false"
                    })}
                >
                    {(part.inventory?.warehouse?.isAvailable ?? "false") ? <span dangerouslySetInnerHTML={{ __html: availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translationState["inventoryPopup.notInStock"]}</span>}
                </span>
                {part.inventory?.branch?.branchName ?
                    <span
                        onClick={() => showInventoryPopup(part)}
                        className={cn("", {
                            "greenText": hasStockAvailable,
                            "redText": !hasStockAvailable,
                        })}
                    >
                        {part.inventory?.branch?.branchName}
                    </span>
                    :
                    <span
                        onClick={() => showInventoryPopup(part)}
                    >
                        {translationState["inventories.selectBranch"]}
                    </span>
                }
            </> : <span className={"truckIcon greenText"}>
                <span className={"d-inline-block firstLetterCapital"}>{translationState["common.deliveryTimeToBeChecked"]}</span>
            </span>}
            </div>
        </div>
        {!noPrice &&
            <div>
                <div className="firstLetterCapital">{translationState["sparePartItem.price"]} :</div>
                <div className="evBold">{part.discountedPrice ?? part.unitPrice}</div>
            </div>
        }
        {!!part.discountedPrice &&
            <div>
                <div className="firstLetterCapital">{translationState["sparePartItem.discount"]} :</div>
                <div>
                    <span className="evCross">{part.unitPrice}</span>
                    <span className="evBold">{part.discountPercentageDisplay}</span>
                </div>
            </div>
        }
        <div>
            <QuantitySelector
                hasRemanInCart={!!part.remanQuantity}
                className="w-100"
                forceMinValue={false}
                step={part.inventory?.quantityStep ?? 1}
                min={part.inventory?.minimumQuantity ?? 0}
                max={part.inventory?.maximumQuantity ?? 0}
                initialValue={part.quantity}
                showLogin={part.showLogin}
                showNotAuthorized={part.showNotAuthorized}
                showRequestQuotationByEmail={part.showRequestQuotationByEmail}
                showContactSupport={part.showContactSupport}
                showBuyButton={part.showBuyButton}
                showAddToCartButton={part.showAddToCartButton}
                requestQuotationByEmail={part.requestQuotationByEmail}
                contactSupportLink={part.contactSupportLink}
                onRemove={(f: () => void) => onItemUpdate(part.code, 0).then(() => f())}
                onChange={(quantity: number) => onItemUpdate(part.code, quantity)}
                buttonLabel={translationState["sparePartItem.buy"]}
                buttonLabelReman={translationState["sparePartItem.buyReman"]}
                buttonLabelAdded={translationState["sparePartItem.added"]}
                buttonLabelRemanAdded={translationState["sparePartItem.addedReman"]}
                buttonLabelAddToCart={translationState["sparePartItem.addToCart"]}
                onAddReman={onAddReman.bind(null, part.code)}
                showRemanufacturePopUp={part.hasRemanItem ? showRemanufacturePopUp.bind(undefined, part.code) : undefined}
            />
        </div>
    </div>;
}